import { Form, FormError, FormGroup } from '@/components/global/form';
import Button from './Components/Shared/Button';
import Tippy from './Components/Shared/Tippy';
import datepicker from './directives/datepicker.js';

export function registerSharedComponents(globalVue) {
    globalVue.component('v-form', Form);
    globalVue.component('v-form-group', FormGroup);
    globalVue.component('v-form-error', FormError);
    globalVue.component('v-button', Button);
    globalVue.component('v-tippy', Tippy);

    globalVue.directive('datepicker', datepicker);
    globalVue.directive('select', {
        inserted(el) {
            // eslint-disable-next-line no-new
            el.$tomSelect = new window.TomSelect(el, {
                hideSelected: false,
                maxOptions: null,
            });
        },

        unbind(el) {
            if (el.$tomSelect) {
                el.$tomSelect.destroy();
            }
        },
    });
}
