<script>
import { FormModel } from '@/components/global/form';
import { scrollToError } from '../../utilities/helpers';

export default {
    name: 'CcClientInformationFrom',

    props: {
        initialData: null,
    },

    data() {
        return {
            loading: false,
            model: null,
        };
    },

    created() {
        this.model = new FormModel(this.initialData);
    },

    methods: {
        async submit() {
            if (this.loading) {
                return;
            }
            this.loading = true;

            try {
                const response = await this.$http.post('/registracija/informacija-apie-klienta', this.model.$);
                window.location = response.data.data.redirect;
            } catch (e) {
                this.model.$e.updateFromResponse(e);
                scrollToError();
                this.$sentyReporting.report(e);
            }

            this.loading = false;
        },

        addCountryOfResidence() {
            this.model.$.countries_of_residence.push({
                title: null,
                tax_identification_number: null,
            });
        },

        removeCountryOfResidence(country) {
            this.model.$.countries_of_residence = this.model.$.countries_of_residence.filter(item => item !== country);
        },
    },
};
</script>
