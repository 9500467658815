<script>
import { FormModel } from '@/components/global/form';
import { scrollToError } from '../../utilities/helpers';

export default {
    name: 'CcPoliticalVulnerabilityInfoForm',

    props: {
        initialData: null,
    },

    data() {
        return {
            loading: false,
            model: null,
        };
    },

    created() {
        this.model = new FormModel(this.initialData);
    },

    computed: {
        isSelfPoliticallyVulnerable() {
            return this.model.$.politically_vulnerable_relations.find(item => item.type === 1);
        },

        canRemoveRelation() {
            return this.model.$.is_politically_vulnerable && this.model.$.politically_vulnerable_relations.length > 1;
        },
    },

    watch: {
        'model.$.is_politically_vulnerable'(value) {
            if (value) {
                if (!this.model.$.politically_vulnerable_relations.length) {
                    this.model.$.politically_vulnerable_relations = [
                        { type: null, full_name: null, info: null },
                    ];
                }
            } else {
                this.model.$.politically_vulnerable_relations = [];
            }
        },
    },

    methods: {
        async submit() {
            if (this.loading) {
                return;
            }
            this.loading = true;

            try {
                const response = await this.$http.post('/registracija/informacija-apie-politini-pazeidziamuma', this.model.$);
                window.location = response.data.data.redirect;
            } catch (e) {
                this.model.$e.updateFromResponse(e);
                scrollToError();
                this.$sentyReporting.report(e);
            }

            this.loading = false;
        },

        addRelation() {
            this.model.$.politically_vulnerable_relations.push({ type: null, full_name: null, info: null });
        },

        removeRelation(relation) {
            const index = this.model.$.politically_vulnerable_relations.indexOf(relation);

            if (index > 0) {
                this.model.$.politically_vulnerable_relations = this.model.$.politically_vulnerable_relations.filter(item => item !== relation);
            }
        },
    },
};
</script>
