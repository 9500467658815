<script>
import { FormModel } from '@/components/global/form';
import { scrollToError } from '../../utilities/helpers';
import swal from 'sweetalert';

export default {
    name: 'CcRegistrationFrom',

    props: {
        initialData: null,
    },

    data() {
        return {
            loading: false,
            model: null,
            creditUnion: null,
        };
    },

    created() {
        this.model = new FormModel(this.initialData);
    },

    watch: {
        'model.$.personal_code'() {
            if (this.model.$.personal_code) {
                this.model.$.personal_code = this.model.$.personal_code.replace(/\D/g, '');
            }
        },

        'model.$.phone'() {
            if (this.model.$.phone) {
                this.model.$.phone = this.model.$.phone.replace(/\D/g, '');
            }
        },

        'model.$.credit_union_id'(value) {
            if (value) {
                const select = this.$el.querySelector('select[name="credit_union_id"]');
                const selectedOption = select.selectedOptions[0] || null;
                this.creditUnion = {
                    address: selectedOption.dataset.address,
                    company_code: selectedOption.dataset.companyCode,
                };
            } else {
                this.creditUnion = null;
            }
        },
    },

    methods: {
        showAlert(notice) {
            if (notice) {
                swal({
                    title: 'Dėmesio!',
                    text: notice,
                    icon: 'info',
                    button: {
                        text: 'Supratau',
                        className: 'btn btn-primary',
                    },
                });
            }
        },

        async submit() {
            if (this.loading) {
                return;
            }
            this.loading = true;

            try {
                const response = await this.$http.post('/api/register', this.model.$);
                window.location = response.data.data.redirect;
            } catch (e) {
                this.model.$e.updateFromResponse(e);
                scrollToError();
                this.$sentyReporting.report(e);
            }

            this.loading = false;
        },
    },
};
</script>
