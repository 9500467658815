<script>
export default {
    name: 'CcIdentityConfirmationForm',

    methods: {
        async loadOnboarding() {
            const response = await this.$http.get('/registracija/asmens-tapatybes-nustatymas/onboarding');
            if (response.data.redirect) {
                window.location = response.data.redirect;
            }
        },
    },
};
</script>
