<script>
import { FormModel } from '@/components/global/form';
import { scrollToError } from '../../utilities/helpers';

export default {
    name: 'CcJobInfoFrom',

    props: {
        initialData: null,
        jobTypes: {
            type: Array,
            default: () => [],
        },
        agricultureIds: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            loading: false,
            model: null,
        };
    },

    created() {
        this.model = new FormModel(this.initialData);
    },

    computed: {
        isAgriculture() {
            return Boolean(this.model.$.individual_activities.find(id => this.agricultureIds.includes(id)));
        },

        jobType() {
            if (!this.model.$.job_type_id) {
                return null;
            }

            return this.jobTypes.find(jobType => jobType.id === this.model.$.job_type_id).type;
        },
    },

    watch: {
        'model.$.job_type_id'(value) {
            if (value === 1) {
                if (!this.model.$.hired_workplaces.length) {
                    this.model.$.hired_workplaces = [{ workplace: '', job_title: '' }];
                }
            } else {
                this.model.$.hired_workplaces = [];
            }
        },
    },

    methods: {
        async submit() {
            if (this.loading) {
                return;
            }
            this.loading = true;

            try {
                const response = await this.$http.post('/registracija/informacija-apie-veikla', this.model.$);
                window.location = response.data.data.redirect;
            } catch (e) {
                this.model.$e.updateFromResponse(e);
                scrollToError();
                this.$sentyReporting.report(e);
            }

            this.loading = false;
        },

        addHiredWorkplace() {
            this.model.$.hired_workplaces.push({ workplace: '', job_title: '' });
        },

        removeHiredWorkplace(relation) {
            const index = this.model.$.hired_workplaces.indexOf(relation);

            if (index > 0) {
                this.model.$.hired_workplaces = this.model.$.hired_workplaces.filter(item => item !== relation);
            }
        },
    },
};
</script>
