<script>
import { FormModel } from '@/components/global/form';

export default {
    name: 'CcConfirmationFrom',

    data() {
        return {
            loading: false,
            model: new FormModel({
                action_type: null,
            }),
            error_message: null,
            iframe_url: null,
        };
    },

    methods: {
        async submit() {
            if (this.loading) {
                return;
            }

            this.loading = true;
            this.error_message = null;

            switch (this.model.$.action_type) {
                case 'e-signature':
                    await this.submitEsignature();
                    break;
                case 'neo-pay':
                    await this.submitNeopay();
                    break;
            }
        },

        async submitNeopay() {
            try {
                const response = await this.$http.get('/api/confirmation/neopay');
                if (response.data.data.redirect) {
                    window.location = response.data.data.redirect;
                }
            } catch (e) {
                this.reset();
                if (e.response.data.data.message) {
                    this.error_message = e.response.data.data.message;
                }
                this.$sentyReporting.report(e);
            }
        },

        async submitEsignature() {
            try {
                const response = await this.$http.get('/api/confirmation/e-signature');
                if (response.data.data.redirect) {
                    window.location = response.data.data.redirect;
                }
            } catch (e) {
                this.reset();
                if (e.response.data.data.message) {
                    this.error_message = e.response.data.data.message;
                }
                this.$sentyReporting.report(e);
            }
        },

        reset() {
            this.loading = false;
            this.error_message = null;
            this.iframe_url = null;
        },
    },
};
</script>
