<script>
import { FormModel } from '@/components/global/form';

export default {
    name: 'CcMembershipFeeFrom',

    data() {
        return {
            loading: false,
            loadingPayNow: false,
            loadingPayLater: false,
            formSent: false,
            model: new FormModel({}),
            error_message: null,
        };
    },

    methods: {
        async payNow() {
            if (this.loading) {
                return;
            }
            this.loading = true;
            this.loadingPayNow = true;
            this.error_message = null;

            try {
                const response = await this.$http.get('/api/membership-fee');
                if (response.data.data.redirect) {
                    window.location = response.data.data.redirect;
                }
            } catch (e) {
                if (e.response.data.data.message) {
                    this.error_message = e.response.data.data.message;
                }

                if (e.response.data.data.redirect) {
                    window.location = e.response.data.data.redirect;
                }

                this.$sentyReporting.report(e);
            }

            this.loading = false;
            this.loadingPayNow = false;
        },

        async payLater() {
            if (this.loading) {
                return;
            }
            this.loading = true;
            this.loadingPayLater = true;
            this.error_message = null;

            try {
                const response = await this.$http.get('/api/membership-fee?pay_later=true');
                if (response.data.data.redirect) {
                    window.location = response.data.data.redirect;
                }
            } catch (e) {
                if (e.response.data.data.message) {
                    this.error_message = e.response.data.data.message;
                }

                if (e.response.data.data.redirect) {
                    window.location = e.response.data.data.redirect;
                }

                this.$sentyReporting.report(e);
            }

            this.loading = false;
            this.loadingPayNow = false;
        },
    },
};
</script>
