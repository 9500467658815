import flatpickr from 'flatpickr';
import { Lithuanian } from 'flatpickr/dist/l10n/lt';

export default {
    inserted(el) {
        flatpickr(el, {
            dateFormat: 'Y-m-d',
            allowInput: true,
            locale: Lithuanian,
            disableMobile: true,
            altInput: true,
            altFormat: 'Y-m-d',
        });
    },
};
